function Checkbox(theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${theme.palette.grey[300]}`
        }
      }
    }
  }
}

export default Checkbox