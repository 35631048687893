function InputLabel(theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle2
        },
        asterisk: {
          color: theme.palette.error.main
        }
      }
    }
  }
}

export default InputLabel