const palette = {
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: {
    main: '#0078e0',
    dark: '#0065bd',
  },
  secondary: {
    main: '#050505',
  },
  error: {
    main: '#df1b1b', // promegranate
    dark: '#b21515'
  },
  warning: {
    main: '#feae01',
  },
  success: {
    main: '#87de26', // atlantis
  },
  text: {
    primary: '#050505',
    secondary: '#545a80',
    disabled: '#8a8fb2',
  },
  background: {
    default: '#e5e5e5',
    100: '#f1f2f4', // seashell / porcelain
    200: '#f7f7f7', // whitesmoke
    300: '#1c242b' // header background
  },
  grey: {
    50: '#050505', // black
    100: '#27313a', // charade
    200: '#414a53', // outer space
    300: '#58646F', // river bed
    400: '#d7dee4' // shuttle grey
  },
  accent: {
    50: '#0e826d', // tropical rain
    100: '#c2f0e1', // mint tulip
    200: '#9317d3' // dark orchid
  }
}

export default palette
