function Tab(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle2,
          color: theme.palette.secondary.main,
          textTransform: 'capitalize',
          height: 24,
          padding: 0,
          minHeight: 32,
          marginRight: 20,
          minWidth: 'auto',
          '&.Mui-selected': {
            fontWeight: 500,
            color: theme.palette.secondary.main
          }
        },
        selected: {
          color: theme.palette.secondary.main
        }
      }
    }
  }
}

export default Tab