import shadows from '../shadows'

function Paper(theme) {
  const shadowsOptions = shadows(theme)

  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.dropdown-menu': {
            width: 200,
            overflow: 'visible',
            border: '1px solid',
            borderColor: theme.palette.grey[400],
            borderRadius: '2px',
            boxShadow: shadowsOptions[0]
          },
          '&.dropdown-draggable': {
            width: 558,
            maxHeight: 542,
            overflow: 'visible',
            border: '1px solid',
            borderColor: theme.palette.grey[400],
            borderRadius: '2px',
            boxShadow: shadowsOptions[1]
          },
          '&.select-menu': {
            width: 'auto',
            height: 'auto',
            minWidth: 200,
            overflow: 'visible',
            border: '1px solid',
            borderColor: theme.palette.grey[400],
            borderRadius: '2px',
            boxShadow: shadowsOptions[0]
          },
          '&.generic-dialog': {
            width: 558,
            overflow: 'visible',
            borderRadius: '2px',
            boxShadow: shadowsOptions[1],
            [theme.breakpoints.down('md')]: {
              width: 482
            }
          },
          '&.generic-dialog-small': {
            width: 468
          },
          '&.preview-dialog': {
            width: 558,
            maxHeight: 660,
            margin: 0,
            padding: theme.spacing(5, 0, 6, 5),
            overflow: 'visible',
            borderRadius: '2px',
            boxShadow: shadowsOptions[1],
            [theme.breakpoints.down('md')]: {
              width: '100%',
              height: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              padding: theme.spacing(6, 0, 0, 17)
            }
          }
        },
      },
    },
  }
}

export default Paper
