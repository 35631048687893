function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle1,
          height: 24,
          padding: theme.spacing(0, 1),
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.common.white,
          borderRadius: '2px',
          border: `1px solid ${theme.palette.grey[400]}`,
          '&:hover': {
            borderColor: theme.palette.grey[300]
          },
          '&.dark': {
            border: 'none',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[200]
          }
        },
        label: {
          padding: 0
        }
      }
    }
  }
}

export default Chip