function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...theme.typography.subtitle1,
          color: theme.palette.common.white,
          backgroundColor: theme.palette.background[300],
          padding: theme.spacing(1, 1.5),
          borderRadius: '2px'
        }
      }
    }
  }
}

export default Tooltip