function MenuItem(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: theme.spacing(0, 1)
        }
      }
    }
  }
}

export default MenuItem