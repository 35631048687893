function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle1,
          padding: 0,
          width: '100%',
          backgroundColor: theme.palette.common.white,
          color: theme.palette.secondary.main
        }
      }
    }
  }
}

export default TextField