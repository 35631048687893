import { alpha } from '@mui/material/styles'

// M-UI v5.2.7 has 25 default elevation levels stored on an array
// add here custom boxShadows; the first one has the (index) number 25
const shadows = (theme) => [
  `0 4px 24px 0 ${alpha(theme.palette.common.black, 0.06)}`, // boxShadow: 1 - border
  `0 4px 24px 0 ${alpha(theme.palette.common.black, 0.08)}`, // boxShadow: 2 - border
]

export default shadows
