function Radio(theme) {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          '&.Mui-checked': {
            color: theme.palette.secondary.main
          }
        }
      }
    }
  }
}

export default Radio