import { alpha } from '@mui/material/styles'

function OutlinedInput(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '100%'
        },
        input: {
          ...theme.typography.subtitle1,
          padding: theme.spacing(0, 1.5),
          borderRadius: '2px',
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.secondary.main,
          height: 40,
          outline: 0,
          width: '100%',
          '&:hover': {
            borderColor: theme.palette.grey[300],
            outline: 0
          },
          '&:focus': {
            borderColor: theme.palette.grey[300],
            outline: 0
          },
          '&:focus-visible': {
            borderColor: theme.palette.grey[300],
            outline: 0
          },
          '&::placeholder': {
            color: theme.palette.grey[300]
          }
        },
        disabled: {
          opacity: 0.6,
          '&:hover': {
            borderColor: alpha(theme.palette.grey[100], 0.6),
            outline: 0
          }
        },
        focused: {
          borderColor: theme.palette.grey[100],
          outline: 0
        },
        notchedOutline: {
          border: 0
        }
      }
    }
  }
}

export default OutlinedInput