import merge from 'lodash/merge'

import Button from './Button'
import Paper from './Paper'
import OutlinedInput from './OutlinedInput'
import TextField from './TextField'
import Tab from './Tab'
import Radio from './Radio'
import Tooltip from './Tooltip'
import InputLabel from './InputLabel'
import MenuItem from './MenuItem'
import Chip from './Chip'
import Checkbox from './Checkbox'

function components(theme) {
  return merge(
    Button(theme),
    Paper(theme),
    OutlinedInput(theme),
    TextField(theme),
    Tab(theme),
    Radio(theme),
    Tooltip(theme),
    InputLabel(theme),
    MenuItem(theme),
    Chip(theme),
    Checkbox(theme)
  )
}

export default components