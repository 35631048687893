function typography(theme, palette) {
  return {
    h1: {
      fontFamily: 'Roboto',
      fontSize: 24,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '32px',
      textAlign: 'center',
      letterSpacing: 0,
      color: palette.text.main
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0,
      color: palette.text.primary
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0,
      color: palette.text.primary
    },
    body1: {
      fontFamily: 'Roboto Mono',
      fontSize: 14,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0,
      color: palette.text.primary
    },
    body2: {
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 0,
      color: palette.text.primary
    },
  }
}

export default typography
