import { alpha } from '@mui/material/styles'

function Button(theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          height: 40,
          borderRadius: '2px',
          padding: theme.spacing(1, 1.5),
          ...theme.typography.subtitle2,
          textAlign: 'center',
          textTransform: 'none'
        },
        outlined: {
          ...theme.typography.subtitle2,
          border: '1px solid',
          borderColor: theme.palette.grey[400],
          color: theme.palette.text.primary,
          '&:hover': {
            borderColor: theme.palette.grey[300],
            backgroundColor: 'unset'
          },
          '&:disabled': {
            opacity: 0.6
          }
        },
        contained: {
          ...theme.typography.subtitle2,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&:disabled': {
            color: alpha(theme.palette.common.white, 0.6),
            backgroundColor: alpha(theme.palette.primary.main, 0.6),
          }
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }
      }
    },
  }
}

export default Button
